<template>
  <button class="button">
    <slot></slot>
  </button>
</template>

<style scoped>
.button {
  cursor: pointer;
  padding: 15px 20px;
  /* border-radius: 10px; */
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ffd915),
    to(#ffa41c)
  );
  background-image: linear-gradient(180deg, #ffd915, #ffa41c); 
  -webkit-transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
    transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
  color: #000;
  background-color: #ffd915;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 3px;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
}

.button:hover {
  box-shadow: 0 14px 40px 0 rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(0px, -2px) scale(1.05);
  -ms-transform: translate(0px, -2px) scale(1.05);
  transform: translate(0px, -2px) scale(1.05);
  color: #000;
}

.button:active {
  box-shadow: none;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}
</style>
