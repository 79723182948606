<template>
  <div class="appContainer" id="top">
    <div class="main">
      <Header :isHome="isHome" />
      <router-view />
    </div>
    <Footer />
  </div>
</template>
<script>
'use strict';

// Imports.
import { mapState, useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { computed } from 'vue';
// Component imports.
import Header from '/src/pages/layout/Header.vue';
import Footer from '/src/pages/layout/Footer.vue';

// Set up the default application component.

export default {
  components: {
    Header,
    Footer
  },

  setup(props, context) {
    const router = useRouter();
    const store = useStore();

    const isHome = computed(() => {
      return router.currentRoute.value.path === '/';
    });
    return {
      router,
      isHome
    };
  },

  computed: {
    ...mapState({
      alert: state => Object.assign({}, state.alert)
    })
  },

  mounted() {
    this.$store.$toasts = this.$toasts;
  },

  watch: {
    alert: {
      deep: true,
      handler: function(newAlert) {
        let message = newAlert.message;
        if (this.lastMessage === message) {
          // TODO remove this once alert hijacking is removed
          return;
        }
        let type = newAlert.type;
        let duration = newAlert.duration;
        let metadata = newAlert.metadata ? newAlert.metadata : {};

        if (message && type) {
          this.$toasts.base('', {
            message: message,
            type: type,
            duration: duration,
            metadata: metadata
          });
          this.lastMessage = message;
        } else {
          // TODO: dispatch a clear event.
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
body {
  background-color: #0c0f15;
}
.appContainer {
  display: flex;
  flex-direction: column;
  background-color: #0c0f15;

  .main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 80%;
    min-height: 80vh;
    overflow: hidden; /* Hide scrollbars */

    .homeHeader {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
